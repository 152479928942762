(<template>
  <div></div>
</template>)

<script>
  export default {
    mounted() {
      const role = this.$store.getters['UserInfoStore/currentRole'];
      const locale = this.$cookieManager.getCookie('serverLocale');
      const country = this.$cookieManager.getCookie('serverCountry');
      const query = {page: 1, availability_date: this.$moment().format('DD/MM/YYYY'), langFrom: '3'};
      this.$store.commit('GlobalStore/startProgress');
      switch (this.$route.name) {
        case 'OldSignIn':
          this.$router.replace({name: 'SignIn', params: {lang: locale, country}});
          break;
        case 'OldSignUp':
          this.$router.replace({name: 'SignUp', params: {lang: locale, country}});
          break;
        case 'OldForgotPassword':
          this.$router.replace({name: 'RestorePassword', params: {lang: locale, country}});
          break;
        case 'OldChangePassword':
          this.$router.replace({name: role == 'server' ? 'ServerChangePassword' : 'BuyerChangePassword', params: {lang: locale, country}});
          break;
        case 'OldCalendar':
          this.$router.replace({name: role == 'server' ? 'ServerCalendar' : 'BuyerCalendar', params: {lang: locale, country}});
          break;
        case 'OldTransactionsHistory':
          this.$router.replace({name: 'SignIn', params: {lang: locale, country}});
          break;
        case 'OldPayoutMethods':
          this.$router.replace({name: 'ServerPayoutsAll', params: {lang: locale, country}});
          break;
        case 'OldPaymentMethods':
          this.$router.replace({name: 'BuyerPaymentMethods', params: {lang: locale, country}});
          break;
        case 'OldAllJobs':
          this.$router.replace({name: role == 'server' ? 'ServerAllAssignments' : 'BuyerAllAssignments', params: {lang: locale, country}});
          break;
        case 'OldCreateJob':
          this.$router.replace({name: 'BuyerSelectAssignmentType', params: {lang: locale, country}});
          break;
        case 'OldJobShow':
          this.$router.replace({name: role == 'server' ? 'ServerOneAssignment' : 'BuyerOneAssignment', params: {id: this.$route.params.id, lang: locale, country}});
          break;
        case 'OldFAI':
          this.$router.replace({name: 'BuyerInterpreters', query, params: {lang: locale, country}});
          break;
        case 'OldManageEmployees':
          this.$router.replace({name: 'BuyerEmployees', params: {lang: locale, country}});
          break;
        case 'OldCreateEnterprise':
          this.$router.replace({name: 'BuyerUpgradeProfile', params: {lang: locale, country}});
          break;
        case 'OldCompanyInfo':
          this.$router.replace({name: 'BuyerCompanyInfo', params: {lang: locale, country}});
          break;
      }
    }
  };
</script>
